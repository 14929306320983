//crypto.js文件内容
import CryptoJS from 'crypto-js'

// https://blog.csdn.net/weixin_47424753/article/details/126331686
// key: "6P7t47zj3dnh5isx",
// iv: "hrYjdyJ2nBjdKB6G"

export default { // 加密
  /**
   * @description: 加密
   * @param {*} word
   * @param {*} keyStr
   */
  set(word, keyStr,ivStr) {
    keyStr = keyStr || '6P7t47zj3dnh5isx';
    ivStr = ivStr || 'hrYjdyJ2nBjdKB6G';
    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let iv = CryptoJS.enc.Utf8.parse(ivStr);
    let srcs = CryptoJS.enc.Utf8.parse( JSON.stringify(word) );
    var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7 
    })
    return encrypted.toString() // 加密后的结果是对象，要转换为文本
  },

  /**
   * @description: 解密
   * @param {*} word
   * @param {*} keyStr
   */
  get(word, keyStr,ivStr) {
    keyStr = keyStr || '6P7t47zj3dnh5isx';
    ivStr = ivStr || 'hrYjdyJ2nBjdKB6G';
    let key = CryptoJS.enc.Utf8.parse(keyStr);
    let iv = CryptoJS.enc.Utf8.parse(ivStr);
    var decrypt = CryptoJS.AES.decrypt(word, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    })
    return JSON.parse( CryptoJS.enc.Utf8.stringify(decrypt).toString() ) //  数组到字符串转换
  }
}
