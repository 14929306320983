<template>
  <div id="app" v-loading="$store.state.api_loading" element-loading-background="rgba(0, 0, 0, 0.6)">
    <router-view></router-view>
  </div>
</template>

<script>
import crypto from "@/crypto/crypto";

export default {
  data() {
    return {
      
    }
  },
  mounted() {
    // ?userid=6507ab65fa1202ec3d728b9f&token=cdce9159cf9ab9e7b9fb9838255bd624
    // token "cdce9159cf9ab9e7b9fb9838255bd624"
    // userid "6507ab65fa1202ec3d728b9f"
    if (window.location.href.indexOf("?") !== -1) {
      const url = new URL(window.location.href);
      const token = url.searchParams.get("token");
      const userid = url.searchParams.get("userid");
      if(token&&userid){
        sessionStorage.setItem("token", crypto.set(token));
        sessionStorage.setItem("userid", crypto.set(userid));
        this.$router.push('/');
      }
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: "PingFang SC","Helvetica Neue",Helvetica,"Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
/* 全局样式 */
.blue_bgc,
.blue_bgc:hover,
.blue_bgc:active,
.blue_bgc:focus,
.blue_bgc:valid{
  background-color: #0A76E2 !important;
  border-color: #0A76E2 !important;
  color: #fff !important;
}
.confirm_btn,
.confirm_btn:hover,
.confirm_btn:active,
.confirm_btn:focus,
.confirm_btn:valid{
  background-color: #0A76E2 !important;
  color: #fff !important;
  min-width: 100px !important;
  border-color: #0A76E2 !important;
}
.cancel_btn,
.cancel_btn:hover,
.cancel_btn:active,
.cancel_btn:focus,
.cancel_btn:valid{
  background-color: #A9A9A9 !important;
  color: #fff !important;
  margin-left: 30px !important;
  min-width: 100px !important;
  border-color: #A9A9A9 !important;
}
.el-dialog__header {
  padding: 40px 20px 10px !important;
}
.el-dialog__footer {
  padding: 10px 20px 35px !important;
}
/* 分页器 */
.pagination_box{
  margin-top: 20px;
  text-align: center;
}
/* ---- */
.search_btn,
.search_btn:hover,
.search_btn:active,
.search_btn:focus,
.search_btn:valid{
  color: #0A76E2 !important;
  min-width: 80px !important;
  border-color: #0A76E2 !important;
}
.reset_btn,
.reset_btn:hover,
.reset_btn:active,
.reset_btn:focus,
.reset_btn:valid{
  color: #838383 !important;
  min-width: 80px !important;
  border-color: #A9A9A9 !important;
  margin-left: 10px !important;
}
/* 进行中 */ /* 安排中 */
.type1{
  color: #0BC97D;
}
.type1_c{
  cursor: pointer;
  color: #0BC97D;
}
/* 已完成 */
.type2{
  color: #A9A9A9;
}
.type2_c{
  cursor: pointer;
  color: #A9A9A9;
}
/* 待处理 */
.type3{
  color: #ED1F44;
}
.type3_c{
  cursor: pointer;
  color: #ED1F44;
}
/* 暂停中 */
.type4{
  color: #FBC00D;
}
.type4_c{
  cursor: pointer;
  color: #FBC00D;
}
/* 已取消 */
.type5{
  color: #5B5B5B;
}
.type5_c{
  cursor: pointer;
  color: #5B5B5B;
}
/* 备注 颜色 */
.remark{
  color: #838383;
}
/* 查看详情 编辑 */
.details{
  cursor: pointer;
  color: #0A76E2;
}
/* 工单详情 */
.o_info_top{
  color: #0A76E2;
}
.state_color{
  color: #5B5B5B;
}
/* 红点 */
.dot1{
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #A9A9A9;
  border-radius: 50%;
  margin-right: 4px;
  vertical-align: middle;
}
.dot_bgc2{
  background-color: #0BC97D;
}
.dot_bgc3{
  background-color: #ED1F44;
}
.dot_bgc4{
  background-color: #FBC00D;
}
.dot_bgc5{
  background-color: #5B5B5B;
}
/* 继续 */
.continue_btn,
.continue_btn:hover,
.continue_btn:active,
.continue_btn:focus,
.continue_btn:valid{
  background-color: #0BC97D !important;
  border-color: #0BC97D !important;
  color: #fff !important;
}
/* 终止 */
.termination_btn,
.termination_btn:hover,
.termination_btn:active,
.termination_btn:focus,
.termination_btn:valid{
  background-color: #ED1F44 !important;
  border-color: #ED1F44 !important;
  color: #fff !important;
}
/* 弹框文字 */
.dlg_body{
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
}
.el-loading-mask {
  z-index: 9999 !important;
}

.el-dialog {
  max-height: 85vh !important;
  overflow: hidden !important;
}
.el-dialog__body {
  overflow: auto !important;
  max-height: 63vh !important;
}
.el-dialog__body::-webkit-scrollbar {
  width: 5px;
}
.el-dialog__body::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 10px;
}
</style>
