// import router from "../../router";

const state = {};
const actions = {
  // RouterAuth: ({ commit }) => {
  //   router.beforeEach((to, from, next) => {
  //     commit;
  //     to;
  //     from;
  //     next;
  //     let token = sessionStorage.getItem("token");
  //     if (Object.is(to.name, "login")) {
  //       if (token) {
  //         next("/");
  //       } else {
  //         next();
  //       }
  //       return false;
  //     } else {
  //       if (token) {
  //         next();
  //       } else {
  //         next("/login");
  //       }
  //       return false;
  //     }
  //   });
  // },
};

export default {
  namespaced: true,
  state,
  actions,
};
