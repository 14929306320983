import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import Vant from 'vant'
import 'vant/lib/index.css';

import {time_format} from './assets/tool/index'
// 时间转换
Vue.prototype.time_format = time_format;

import pcormove from './assets/tool/pcormove'; // 判断pc还是移动
import msg from './assets/tool/msg'; //消息提示框

import i18n from './i18n';

Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(Vuex);
Vue.config.productionTip = false

Vue.prototype.bus = new Vue();
Vue.prototype.pcormove = pcormove._isMobile();
Vue.prototype.msg = msg.msg;

sessionStorage.getItem('language')||sessionStorage.setItem('language','ch');

const lang = sessionStorage.getItem('language');
Vue.prototype.lang = lang;
Vue.prototype.bus = new Vue();
// store.dispatch('login/RouterAuth') //路由守卫

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
