module.exports = {//例子 this.$i18n.t('main.submit_audit')  {{$t('main.status_name')}}

    main:{
        text1               :"Create call list",
        text2               :"Logout",
        text3               :"Confirm logout",
        text4               :"Confirm",
        text5               :"Cancel",
        text6               :"Call list",
        text7               :"candidate",
        text8               :"Account Mangment",
        text9               :"Settings",
        text10              :"Create account",
        text11              :"Enter search contents",
        text12              :"Search",
        text13              :"Reset",
        text14              :"No.",
        text15              :"Username",
        text16              :"Company Email",
        text17              :"Company Name",
        text18              :"Mobile no.",
        text19              :"Number of call lists",
        text20              :"Call lists in progress",
        text21              :"Action",
        text22              :"Pause",
        text23              :"Start",
        text24              :"Edit",
        text25              :"Save",
        text26              :"Cancel",
        text27              :"Reset Password",
        text28              :"Create account",
        text29              :"Company Email",
        text30              :"Enter company email",
        text31              :"Company name",
        text32              :"Enter company name",
        text33              :"Bind mobile no.",
        text34              :"Enter mobile no.",
        text35              :"Name",
        text36              :"Enter name",
        text37              :"Generate account",
        text38              :"Enable",
        text39              :"Confirm",
        text40              :"Freeze",
        text41              :"Do you want to reset your password? After resetting, an email will be sent to your mailbox. Please check it.",
        text42              :"Email format error",
        text43              :"Create success",
        text44              :"Edit success",
        text45              :"Action success",
        text46              :"Reset success",
        text47              :"Candidate",
        text48              :"Candidate details",
        text49              :"Conversation history",
        text50              :"Call list records",
        text51              :"Call list details",
        text52              :"Call list no.",
        text53              :"Date",
        text54              :"Status",
        text55              :"Processing",
        text56              :"Interviewer have not respond to interview timing",
        text57              :"Contacting candidate",
        text58              :"Candidiate request change of interview timing",
        text59              :"Candidate forego interview",
        text60              :"Candidate contact incorrect",
        text61              :"Interview arrangement pause",
        text62              :"Interveiw cancelled",
        text63              :"Interveiwer to confirm interview",
        text64              :"Interveiw arranged",
        text65              :"Interview timing changed",
        text66              :"Interview completed",
        text67              :"No show",
        text68              :"Interview arrangement stopped",
        text69              :"Change of status history",
        text70              :"No.",
        text71              :"Conversation ID",
        text72              :"Time",
        text73              :"status update",
        text74              :"Processing",
        text75              :"Interviewer have not respond to interview timing",
        text76              :"Confirm to change interview timing?",
        text77              :"Yes",
        text78              :"No",
        text79              :"Remarks",
        text80              :"Cancel interview",
        text81              :"Pause interview",
        text82              :"Conversation history details",
        text83              :"More",
        text84              :"Conversation details",
        text85              :"Candidate",
        text86              :"Candidate details",
        text87              :"Full name",
        text88              :"Mobile no.",
        text89              :"Email",
        text90              :"Call list no.",
        text91              :"Call list status update date",
        text92              :"status",
        text93              :"Status history",
        text94              :"Details",
        text95              :"Continue?",
        text96              :"Stop?",
        text97              :"Select date and time",
        text98              :"Enter search contents",
        text99              :"Search",
        text100             :"Action",
        text101             :"Details",
        text102             :"Add candidates",
        text103             :"Enter candidate name",
        text104             :"Enter candidate mobile no.",
        text105             :"Enter candidate email",
        text106             :"Position",
        text107             :"Enter candidate position",
        text108             :"Select status",
        text109             :"Awaiting interview",
        text110             :"Already employed",
        text111             :"Are you sure you want to delete this candidate?",
        text112             :"Add success",
        text113             :"Delete success",
        text114             :"Edit success",
        text115             :"Hello, interviewer!",
        text116             :"Since the candidate has modified the time, in order to ensure a smooth interview, please reply whether you agree to the modified time:",
        text117             :"The new interview timing provided by the candidate is",
        text118             :"Agree",
        text119             :"Disagree",
        text120             :"Submit",
        text121             :"Please choose to agree to the new timing",
        text122             :"Action success",
        text123             :"Hello!",
        text124             :"To ensure a smooth interview, please fill in the following information:",
        text125             :"Do you want to proceed with the interview?",
        text126             :"Proceed to the interview",
        text127             :"Estimated interview length",
        text128             :"Minutes",
        text129             :"Recommended interview time",
        text130             :"If you are unable to attend the interview during the recommended interview time, please select an available interview time from the following time periods",
        text131             :"Select time",
        text132             :"The selected time is",
        text133             :"If you are unable to attend during any of the above times, please provide a timing that you can attend.",
        text134             :"Please select a start time",
        text135             :" Please be aware that the new Interview timing cannot be guaranteed during this time period",
        text136             :"Not participating",
        text137             :"Please enter the reason",
        text138             :"Do you proceed with the interview?",
        text139             :"Please select a time",
        text140             :"Please enter the reason for rejection",
        text141             :"Add timing",
        text142             :"Add interview timing",
        text143             :"Interview timing",
        text144             :"can use 1200hrs - 1300hrs",
        text145             :"add timing",
        text146             :"Submit",
        text147             :"Please add timing",
        text148             :"Submit success",
        text149             :"Estimated interview length",
        text150             :"Proposed interview timing",
        text151             :"Forget password",
        text152             :"Email address",
        text153             :"Enter email address",
        text154             :"OTP code",
        text155             :"Enter OTP code",
        text156             :"Send OTP",
        text157             :"New password",
        text158             :"Enter new password",
        text159             :"Confirm new password",
        text160             :"Enter new password",
        text161             :"Login",
        text162             :"Email cannot be empty",
        text163             :"email format error",
        text164             :"OTP code cannot be empty",
        text165             :"Password cannot be empty",
        text166             :"Password format error",
        text167             :"Confirm password cannot be empty",
        text168             :"Password incorrect",
        text169             :"Reser password success",
        text170             :"Email cannot be empty",
        text171             :"Welcome back to your system",
        text172             :"Please login",
        text173             :"Email address",
        text174             :"Enter email address",
        text175             :"Password",
        text176             :"Enter password",
        text177             :"Forget password",
        text178             :"Account",
        text179             :"Edit",
        text180             :"Company name",
        text181             :"Email address",
        text182             :"Edit",
        text183             :"Please bind mobile no.",
        text184             :"bind",
        text185             :"Edit login password",
        text186             :"Please enter new password",
        text187             :"Confirm new password",
        text188             :"Letters + numbers 6-12 characters",
        text189             :"edit email address",
        text190             :"Enter password",
        text191             :"Set new email address",
        text192             :"Verify new email address",
        text193             :"Enter login password to verify",
        text194             :"Enter new email address",
        text195             :"OTP Sent",
        text196             :"Enter OTP code",
        text197             :"Complete",
        text198             :"Next",
        text199             :"Enter login password",
        text200             :"Enter new email address",
        text201             :"Enter new email OTP code",
        text202             :"Enter new login password",
        text203             :"Two password not the same",
        text204             :"Enter mobile no.",
        text205             :"Enter username",
        text206             :"Position details",
        text207             :"Interveiwed Position",
        text208             :"Enter position",
        text209             :"Interview details",
        text210             :"Interveiw methods",
        text211             :"Select a interview methods",
        text212             :"In person",
        text213             :"Online",
        text214             :"Include online interview",
        text215             :"Online platfrom",
        text216             :"Please select a interview online platform",
        text217             :"Zoom",
        text218             :"Interview location",
        text219             :"Enter interview loction",
        text220             :"Interview length",
        text221             :"Enter interview length (Include rest)",
        text222             :"Arrangment methods",
        text223             :"Tele + Email",
        text224             :"Email",
        text225             :"Interviewer details",
        text226             :"Name",
        text227             :"Enter interviewer full name",
        text228             :"Mobile no.",
        text229             :"Enter interviewer mobile numner",
        text230             :"Email",
        text231             :"Enter interviewer email",
        text232             :"Interview timing",
        text233             :"Use",
        text234             :"Cannot use",
        text235             :"The interview time includes non-working hours, please double-check whether the interview time is accurate",
        text236             :"Add timing",
        text237             :"Candidate details",
        text238             :"Select from candidate list",
        text239             :"Hibuddy will make a call based on the order of the candidate information table",
        text240             :"Please enter candidate name",
        text241             :"Delete ",
        text242             :"Move up",
        text243             :"Move down",
        text244             :"Add candidate",
        text245             :"Start call timing",
        text246             :"Start time",
        text247             :"The time to start the call list is less than 1 hour. Please confirm whether the start time is accurate.",
        text248             :"The time to start thecall list is not during working hours. Please confirm whether the start time is accurate.",
        text249             :"Enter search contents",
        text250             :"Awaiting interview",
        text251             :"Already employed",
        text252             :"Please enter the interview position",
        text253             :"Please enter the interviewer's name",
        text254             :"Please enter candidate name",
        text255             :"Please select the interview method",
        text256             :"Please select an interview platform",
        text257             :"Please enter the interview location",
        text258             :"Please select the interview duration (including breaks)",
        text259             :"Please select the invitation method",
        text260             :"Please confirm the interviewer information",
        text261             :"Please confirm candidate information",
        text262             :"Edited success",
        text263             :"The selected time is less than the interview time, please choose the time reasonably.",
        text264             :"Creator",
        text265             :"Create date",
        text266             :"Not started",
        text267             :"Pending",
        text268             :"Arranging",
        text269             :"In progress",
        text270             :"Completed",
        text271             :"Interview Timing",
        text272             :"Postphoned",
        text273             :"Restart",
        text274             :"History",
        text275             :"Pause",
        text276             :"Cancel",
        text277             :"Stop",
        text278             :"Postphone",
        text279             :"Restart",
        text280             :"Completed",
        text281             :"In progress",
        text282             :"Arranging",
        text283             :"Pending",
        text284             :"Not started",
        text285             :"Interviewer",
        text286             :"Total candidate",
        text287             :"Check details",
        text288             :"Once a call list is started, it cannot be edited or canceled. Do you want to start it?",
        text289             :"Start",
        text290             :"Pause",
        text291             :"Termination",

        
    }

}