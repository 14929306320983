import Vue from "vue";
import VueRouter from "vue-router";
import layout from "@/layout/layout.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    meta: { noAuth: true },
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/forget_pwd",
    name: "forget_pwd",
    meta: { noAuth: true },
    component: () => import("@/views/forget_pwd/forget_pwd.vue"),
  },
  {
    path: "/", //工单
    component: layout,
    children: [
      {
        path: "/",
        name: "work_order",
        component: () => import("@/views/work_order/work_order.vue"),
      },
    ],
  },
  {
    path: "/", //工单详情
    component: layout,
    children: [
      {
        path: "/work_order_info",
        name: "work_order_info",
        component: () => import("@/views/work_order/work_order_info.vue"),
      },
    ],
  },
  {
    path: "/", //新建工单
    component: layout,
    children: [
      {
        path: "/new_work_order",
        name: "new_work_order",
        component: () => import("@/views/work_order/new_work_order.vue"),
      },
    ],
  },{
    path: "/", //编辑工单
    component: layout,
    children: [
      {
        path: "/edit_new_work_order",
        name: "edit_new_work_order",
        component: () => import("@/views/work_order/edit_new_work_order.vue"),
      },
    ],
  },
  {
    path: "/candidate", //候选人
    component: layout,
    children: [
      {
        path: "/candidate",
        name: "candidate",
        component: () => import("@/views/candidate/candidate.vue"),
      },
    ],
  },
  {
    path: "/candidate_info", //候选人详情
    component: layout,
    children: [
      {
        path: "/candidate_info",
        name: "candidate_info",
        component: () => import("@/views/candidate/candidate_info.vue"),
      },
    ],
  },
  {
    path: "/candidate_info_history", //候选人详情 历史对话
    component: layout,
    children: [
      {
        path: "/candidate_info_history",
        name: "candidate_info_history",
        component: () => import("@/views/candidate/candidate_info_history.vue"),
      },
    ],
  },
  {
    path: "/account_manage", //账户管理
    name: "account_manage",
    component: layout,
    children: [
      {
        path: "/account_manage",
        component: () => import("@/views/account_manage/account_manage.vue"),
      },
    ],
  },
  {
    path: "/setting", //设置
    component: layout,
    children: [
      {
        path: "/setting",
        name: "setting",
        component: () => import("@/views/setting/setting.vue"),
      },
    ],
  },

  {
    path: "/email", //邮件
    name: "email",
    meta: { noAuth: true },
    component: () => import("@/views/email/email.vue"),
  },
  {
    path: "/invite_email", //邮件
    name: "invite_email",
    meta: { noAuth: true },
    component: () => import("@/views/email/invite_email.vue"),
  },
  {
    path: "/email_set_time", //邮件 修改时间
    name: "email_set_time",
    meta: { noAuth: true },
    component: () => import("@/views/email/email_set_time.vue"),
  },
  {
    path: "/interviewer_add_time_email", //邮件 面试官加时间
    name: "interviewer_add_time_email",
    meta: { noAuth: true },
    component: () => import("@/views/email/interviewer_add_time_email.vue"),
  },

  {
    path: "*",
    name: "404",
    meta: { noAuth: true },
    component: () => import("@/views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.name == "email"||to.name == "email_set_time"||to.name == "forget_pwd"||to.name == "invite_email"||to.name == "interviewer_add_time_email"){
    return next();
  }
  const token = sessionStorage.getItem("token");
  const userid = sessionStorage.getItem("userid");
  if (!to.meta.noAuth) {
    if (!token || !userid) {
      return next({ name: "login" });
    } else {
      return next();
    }
  } else {
    if (token && userid) {
      return next({ name: "work_order" });
    } else {
      return next();
    }
  }
  // next();
});

export default router;
