module.exports = {//例子 this.$i18n.t('main.submit_audit')  {{$t('main.status_name')}}

    main:{
        text1               :"新建工单",
        text2               :"退出登录",
        text3               :"确认退出登录？",
        text4               :"确定",
        text5               :"取消",
        text6               :"工单",
        text7               :"候选人",
        text8               :"账户管理",
        text9               :"设置",
        text10              :"创建账户",
        text11              :"输入搜索内容",
        text12              :"搜索",
        text13              :"重置",
        text14              :"序号",
        text15              :"用户名",
        text16              :"公司邮箱",
        text17              :"公司名称",
        text18              :"手机号",
        text19              :"订单数量",
        text20              :"进行中订单数量",
        text21              :"操作",
        text22              :"停用",
        text23              :"启用",
        text24              :"编辑",
        text25              :"保存",
        text26              :"取消",
        text27              :"重置密码",
        text28              :"创建账户",
        text29              :"公司邮箱",
        text30              :"请输入公司邮箱",
        text31              :"公司名称",
        text32              :"请输入公司名称",
        text33              :"绑定手机",
        text34              :"请输入绑定手机",
        text35              :"名字",
        text36              :"请输入名字",
        text37              :"生成账号",
        text38              :"是否启用",
        text39              :"确定",
        text40              :"是否停用",
        text41              :"是否重置密码，重置后会发邮件到您的邮箱，注意查收。",
        text42              :"邮箱格式错误",
        text43              :"创建成功",
        text44              :"编辑成功",
        text45              :"操作成功",
        text46              :"重置成功",
        text47              :"候选人",
        text48              :"候选人详情",
        text49              :"历史对话",
        text50              :"工单列表",
        text51              :"工单详情",
        text52              :"工单号",
        text53              :"时间",
        text54              :"最新状态",
        text55              :"处理中",
        text56              :"面试官未回复面试时间",
        text57              :"候选人联系中",
        text58              :"候选人要求调整待面试官确认",
        text59              :"候选人放弃",
        text60              :"候选人联系方式错误",
        text61              :"面试安排暂停",
        text62              :"面试取消",
        text63              :"待面试官确认",
        text64              :"面试已安排",
        text65              :"面试改期",
        text66              :"面试已完成",
        text67              :"候选人爽约",
        text68              :"面试安排终止",
        text69              :"状态更新历史",
        text70              :"序号",
        text71              :"对话ID",
        text72              :"时间",
        text73              :"状态更新",
        text74              :"处理中",
        text75              :"面试官未回复面试时间",
        text76              :"是否改期",
        text77              :"否",
        text78              :"是",
        text79              :"备注",
        text80              :"取消面试",
        text81              :"暂停面试",
        text82              :"历史对话详情",
        text83              :"更多",
        text84              :"对话详情",
        text85              :"候选人",
        text86              :"候选人详情",
        text87              :"姓名",
        text88              :"电话",
        text89              :"邮箱",
        text90              :"工单号",
        text91              :"工单状态更新时间",
        text92              :"状态",
        text93              :"状态历史",
        text94              :"查看",
        text95              :"是否继续",
        text96              :"是否终止",
        text97              :"选择日期时间",
        text98              :"输入搜索内容",
        text99              :"搜索",
        text100             :"操作",
        text101             :"详情",
        text102             :"添加候选人",
        text103             :"请输入候选人姓名",
        text104             :"请输入候选人电话",
        text105             :"请输入候选人邮箱",
        text106             :"岗位",
        text107             :"请输入候选人岗位",
        text108             :"请选择状态",
        text109             :"待⾯试",
        text110             :"已入职",
        text111             :"确认要删除该候选人吗？",
        text112             :"添加成功",
        text113             :"删除成功",
        text114             :"编辑成功",
        text115             :"面试官，您好！",
        text116             :"由于候选人修改了时间，为了确保面试顺利，请回复是否同意修改时间：",
        text117             :"候选人提供的时间段为：",
        text118             :"同意",
        text119             :"不同意",
        text120             :"提交",
        text121             :"请选择是否同意修改时间",
        text122             :"操作成功",
        text123             :"您好！",
        text124             :"为了确保面试顺利，请填写以下信息：",
        text125             :"您是否继续参加面试？",
        text126             :"需要参加",
        text127             :"预估面试时长",
        text128             :"分钟",
        text129             :"建议面试时间",
        text130             :"如建议面试时间无法参加面试，请从以下时间段选择可参加面试时间",
        text131             :"选择时间",
        text132             :"选中的时间为",
        text133             :"若以上时间均不能参加，请提供可参加时间段",
        text134             :"请选择开始时间",
        text135             :"此时间段无法确保安排面试，请知悉",
        text136             :"不参加",
        text137             :"请输入原因",
        text138             :"您是否继续参加面试？",
        text139             :"请选择时间",
        text140             :"请输入拒绝理由",
        text141             :"已添加时间",
        text142             :"添加面试时间",
        text143             :"面试时间：",
        text144             :"12:00-13:00可用",
        text145             :"添加时间",
        text146             :"提交",
        text147             :"请添加时间",
        text148             :"提交成功",
        text149             :"预估面试时长",
        text150             :"建议面试时间",
        text151             :"忘记密码",
        text152             :"邮箱账户",
        text153             :"请输入邮箱账户",
        text154             :"验证码",
        text155             :"请输入验证码",
        text156             :"发送验证码",
        text157             :"新密码",
        text158             :"请输入新密码",
        text159             :"确认新密码",
        text160             :"请确认新密码",
        text161             :"登录",
        text162             :"邮箱账户不能为空",
        text163             :"邮箱格式错误",
        text164             :"验证码不能为空",
        text165             :"密码不能为空",
        text166             :"密码格式错误",
        text167             :"确认密码不能为空",
        text168             :"两次密码不一致",
        text169             :"密码设置成功",
        text170             :"邮箱账户不能为空",
        text171             :"欢迎回到我们的平台！",
        text172             :"请在下方登录您的账户",
        text173             :"邮箱账户",
        text174             :"请输入邮箱账户",
        text175             :"密码",
        text176             :"请输入密码",
        text177             :"忘记密码？",
        text178             :"用户",
        text179             :"修改",
        text180             :"公司名",
        text181             :"邮箱地址",
        text182             :"修改",
        text183             :"请绑定手机号",
        text184             :"绑定",
        text185             :"修改登录密码",
        text186             :"请填写新的登录密码",
        text187             :"请确认新的登录密码",
        text188             :"字母+数字 6-12个字符",
        text189             :"修改绑定邮箱",
        text190             :"输入登录密码",
        text191             :"设置新邮箱",
        text192             :"验证新邮箱",
        text193             :"输入登录密码，用于验证身份",
        text194             :"输入新邮箱地址",
        text195             :"验证码已发送至",
        text196             :"输入新邮箱验证码",
        text197             :"完成",
        text198             :"下一步",
        text199             :"请输入登录密码",
        text200             :"请输入新邮箱地址",
        text201             :"请输入新邮箱验证码",
        text202             :"请填写新的登录密码",
        text203             :"两次密码不一致",
        text204             :"请输入手机号码",
        text205             :"请填写用户名",
        text206             :"岗位信息",
        text207             :"面试岗位：",
        text208             :"请输入面试岗位",
        text209             :"面试信息",
        text210             :"面试方式：",
        text211             :"请选择方式",
        text212             :"线下面试",
        text213             :"线上面试",
        text214             :"可以线上面试",
        text215             :"面试平台：",
        text216             :"请选择面试平台",
        text217             :"Zoom",
        text218             :"面试地点：",
        text219             :"请输入面试地点",
        text220             :"面试时长：",
        text221             :"请输入面试时长（含间歇）",
        text222             :"邀约方式：",
        text223             :"电话+邮件",
        text224             :"邮件",
        text225             :"面试官信息",
        text226             :"姓名：",
        text227             :"请输入面试官姓名",
        text228             :"手机号：",
        text229             :"请输入手机号",
        text230             :"邮箱：",
        text231             :"请输入邮箱",
        text232             :"面试时间",
        text233             :"可用",
        text234             :"不可用",
        text235             :"面试时间包含非工作时间，请再确认面试时间是否准确",
        text236             :"添加时间",
        text237             :"候选人信息",
        text238             :"从候选人列表添加",
        text239             :"Hibuddy会根据候选人信息表的排序拨打电话",
        text240             :"请输入候选人姓名",
        text241             :"删除",
        text242             :"上移",
        text243             :"下移",
        text244             :"添加候选人",
        text245             :"启动工单时间",
        text246             :"开始时间：",
        text247             :"启动工单时间与面试时间少过1个钟，请确认启动时间是否准确",
        text248             :"启动工单时间非工作时间，请再确认启动时间是否准确",
        text249             :"输入搜索内容",
        text250             :"待面试",
        text251             :"已入职",
        text252             :"请输入面试岗位",
        text253             :"请输入面试官姓名",
        text254             :"请输入候选人姓名",
        text255             :"请选择面试方式",
        text256             :"请选择面试平台",
        text257             :"请输入面试地点",
        text258             :"请选择面试时长（含间歇）",
        text259             :"请选择邀约方式",
        text260             :"请先确定面试官信息",
        text261             :"请先确定候选人信息",
        text262             :"编辑成功",
        text263             :"选择的时常小于面试时常，请合理选择时常",
        text264             :"创建人",
        text265             :"创建时间",
        text266             :"未开始",
        text267             :"待处理",
        text268             :"安排中",
        text269             :"进行中",
        text270             :"已完成",
        text271             :"面试安排时间",
        text272             :"改期",
        text273             :"重新启动",
        text274             :"历史",
        text275             :"是否暂停",
        text276             :"是否取消",
        text277             :"是否终止",
        text278             :"是否改期",
        text279             :"是否重新启动",
        text280             :"已完成",
        text281             :"进行中",
        text282             :"安排中",
        text283             :"待处理",
        text284             :"未开始",
        text285             :"面试官",
        text286             :"候选人数量",
        text287             :"查看详情",
        text288             :"工单一旦开始则不可以编辑和取消，是否开始？",
        text289             :"开始",
        text290             :"暂停",
        text291             :"终止",
        

    }

}