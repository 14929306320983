<template>

  <el-container>
    <el-aside width="240px"><Sidebar/></el-aside>
    <el-container>
      <el-header height="50px"><Header/></el-header>
      <el-main><router-view/></el-main>
    </el-container>
  </el-container>

</template>

<script>
export default {
  components:{
    Header:() => import("./components/header"),
    Sidebar:() => import('./components/sidebar')
  },
  data() {
    return {
      
    }
  },
}
</script>

<style scoped>
.el-header {
  padding: 0 !important;
}
.el-main {
  height: calc(100vh - 50px);
  overflow: auto;
  box-sizing: border-box;
  padding: 30px;
  background: #F4F5FA;
}
.el-main::-webkit-scrollbar {
  width: 5px;
}
.el-main::-webkit-scrollbar-thumb {
  background: #d3d3d3;
  border-radius: 10px;
}
</style>