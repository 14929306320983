import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementLocale from 'element-ui/lib/locale';
import cnLocale from './language/ch';
import enLocale from './language/en';

import elEnLocale from 'element-ui/lib/locale/lang/en';
import elCnLocale from 'element-ui/lib/locale/lang/zh-CN';

Vue.use(VueI18n);

let currentLang = sessionStorage.getItem('language')||'ch';

if (!currentLang) {
  sessionStorage.setItem('language', currentLang);
}

const i18n = new VueI18n({
  locale: currentLang,
  silentFallbackWarn: true,
  messages: {
    ch: {
      ...cnLocale,
      ...elCnLocale,
    },
    en: {
      ...enLocale,
      ...elEnLocale,
    },
  }
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n