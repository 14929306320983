import { Message } from "element-ui"
// import { Notification } from 'element-ui';

import pcormove from './pcormove'

import { Toast } from 'vant';




const msg = (type, msg) => {
    // console.log(pcormove._isMobile())
    if (pcormove._isMobile() == 'app') {
        if (type == 'success') {
            Toast(msg);
        } else {
            Toast(msg);
        }
    } else {
        Message.closeAll();
        if(type == 'success'){
            Message({
                message: msg,
                type: 'success'
            })
        }else if(type == 'error'){
            Message({
                message: msg,
                type: 'error'
            })
        }else{
            Message({
                message: msg,
                type: 'info'
            })
        }

        // Notification.closeAll();
        // if(type == 'success'){
        //     Notification({
        //         message: msg,
        //         type: 'success',
        //         showClose: false,
        //         duration:3000,
        //         customClass:'succ_notify'
        //     })
        // }else if(type == 'error'){
        //     Notification({
        //         message: msg,
        //         type: 'error',
        //         showClose: false,
        //         duration:3000,
        //         customClass:'err_notify'
        //     })
        // }else{
        //     Notification({
        //         message: msg,
        //         type: 'info',
        //         showClose: false,
        //         // duration:0 0是 不自动关闭
        //         duration:3000
        //     })
        // }
        
    }
}

export default {
    msg
}