// 使用时间转换插件 moment.js
import moment from 'moment'
let lang = localStorage.getItem('language')||"ch";
// 全局  overall
export function time_format(v) {
  if(lang=='en'){
    return moment(v).format('DD/MM/YYYY, hh:mma');
  }else{
    return moment(v).format('YYYY/MM/DD HH:mm');
  }
}
